<template>
    <div class="conversation-container">
        <div class="chat-body">
            <!-- Messages -->
            <div id="chat-messages">
                <Message v-for="(message, index) in messages" :key="`message-${index}`" :item="message" />
            </div>

            <div class="clearfix"></div>

            <!-- Answer area -->
            <div v-if="answer" class="answer-add">
                <div class="editor-and-dropzone">
                    <!-- Rich text editor instead of a simple textarea -->
                    <Editor :text.sync="response" :config="editorConfig" />

                    <!-- Optional Dropzone -->
                    <template v-if="upload">
                        <Dropzone ref="dzone" class="dropzone"></Dropzone>
                    </template>
                </div>

                <!-- Send button -->
                <el-button type="info" plain icon="el-icon-s-promotion" class="send-button" @click="send">
                    {{ $t('general.send') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
const DropzoneComp = () => import('@/components/Dropzone/Index.vue');
const EditorComp = () => import('@/components/Editor');
const MessageComp = () => import('./message.vue');

export default {
    props: {
        messages: {
            type: Array,
            required: true,
        },
        upload: {
            type: Boolean,
            default: false,
        },
        answer: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            response: null,
            editorConfig: {
                height: 120, // Adjust the editor height as desired
            },
        };
    },
    components: {
        Message: MessageComp,
        Dropzone: DropzoneComp,
        Editor: EditorComp,
    },
    methods: {
        send() {
            if (!this.response || this.response.length < 10) {
                this.sbMsg({
                    message: this.$t('comm.complaints.error-response'),
                    type: 'error',
                });
                return;
            }

            let files = [];
            if (this.upload) {
                files = this.$refs.dzone.$refs.dropzoneComp.getAcceptedFiles();
            }

            // Emit the response + optional files to the parent
            this.$emit('send', this.response, files);

            // Clear fields
            this.response = null;
            if (this.upload) {
                this.$refs.dzone.$refs.dropzoneComp.removeAllFiles();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.conversation-container {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 4px;
    margin-bottom: 20px;
}

/* The main chat container */
.chat-body {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    #chat-messages {
        position: relative;
        margin-bottom: 20px;

        /* Clearfix to contain floated message bubbles */
        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .answer-add {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px; /* space between editor area and send button */
        background: rgba(209, 209, 209, 0.14);
        padding: 15px;
        border-radius: 4px;

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: stretch;
        }

        .editor-and-dropzone {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .dropzone {
                min-height: 60px !important;
                border-radius: 5px;
                border-width: 1px !important;

                .dz-default.dz-message {
                    margin: 0;
                    font-size: 14px;
                }
            }
        }

        .send-button {
            white-space: nowrap; /* Prevent button text from wrapping */
            align-self: flex-start;

            @media (max-width: 600px) {
                align-self: center;
                width: 100%;
            }
        }
    }
}

/* Clearfix utility */
.clearfix::after {
    content: '';
    display: table;
    clear: both;
}
</style>
